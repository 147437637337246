import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";

import api from "../../api";
import SearchInput from "./SearchInput";
import SearchResult from "./SearchResult";
import { convertStringWithSpaceIntoArray } from "./Constants";

const SearchComponent = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [apiRequest, setApiRequest] = useState(true);
  const [showSuggestionView, setShowSuggestionView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [dictList, setDictList] = useState([]);
  const [initialDictList, setInitialDictList] = useState([]);
  const [secondSetDictList, setSecondSetDictList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [itemList, setItemList] = useState([]);

  const [searchGender, setSearchGender] = useState("");
  const [searchGenderId, setSearchGenderId] = useState("");
  const [searchCategoryId, setSearchCategoryId] = useState("");
  const [searchCategoryName, setSearchCategoryName] = useState("");
  const [searchSegmentId, setSearchSegmentId] = useState("");
  const [searchSegmentName, setSearchSegmentName] = useState("");
  const [searchSubCategoryId, setSearchSubCategoryId] = useState("");
  const [searchSubCategoryName, setSearchSubCategoryName] = useState("");
  const [colorIdList, setColorIdList] = useState("");
  const [catList, setCatList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [colorNameList, setColorNameList] = useState([]);
  const [callFrom, setCallFrom] = useState("");
  const [visibleView, setVisibleView] = useState("default");
  const [categoryNameList, setCategoryNameList] = useState([]);
  const [colorIds, setColorIds] = useState("");
  // const onTextChanged = (e) => {
  //   const value = e.target.value;
  //   console.log(value, "value");
  //   setSearchText(value);
  // };
  // useEffect(() => {
  //   console.log("searchText", searchText);
  //   if (searchText.trim().length > 2) {
  //     setItemList([]);
  //     setValue("");
  //     setType("");

  //     searchSuggestions();
  //   } else if (searchText.trim().length >= 1 && searchText.trim().length < 2) {
  //     // for initial time

  //     setItemList([]);
  //     setValue("");
  //     setType("");
  //     getsearchDict();
  //   } else {
  //     // getsearchDict();
  //     setItemList([]);
  //     setValue("");
  //     setType("");
  //     showDefaultView();
  //   }
  // }, [searchText, type, value]);

  const onTextChanged = (e) => {
    // setValue("");
    // setType("");

    const value = e.target.value;
    console.log(value, "value");
    setTextLength(value.length);
    setSearchText(value);
  };

  useEffect(() => {
    console.log("searchText", searchText);
    console.log("textLength", textLength);

    const trimmedSearch = searchText.trim();

    if (trimmedSearch.length > 2) {
      setItemList([]);
      // setValue("");
      // setType("");
      showDefaultView();
      searchSuggestions();
    } else if (trimmedSearch.length > 0) {
      setItemList([]);
      setValue("");
      setType("");
      showDefaultView();
      getsearchDict();
    } else {
      setItemList([]);
      setValue("");
      setType("");
      showDefaultView();
    }
  }, [searchText, type]);

  const filterFirstSet = () => {
    setItemList([]);
    // setSecondSetDictList([]);
    const _text = searchText.replace(" ", "").toLowerCase();
    const filterPattern = _text.toLowerCase().trim();
    const filteredItems =
      initialDictList && initialDictList != null && initialDictList.length > 0
        ? initialDictList.filter(
            (item) =>
              item.name != null &&
              item.name.length > 0 &&
              item.name.toLowerCase().includes(filterPattern)
          )
        : [];

    if (filteredItems.length > 0) {
      setItemList(filteredItems);
      // setSecondSetDictList(filteredItems);
      showFilterListView();
    } else {
      // setSecondSetDictList([]);
      showDefaultView();
      setApiRequest(false);
    }
  };

  const filterSecondSet = () => {
    // setSecondSetDictList([]);

    // let _text = searchText;
    // const filterPattern = _text.toLowerCase().trim();

    // const filteredItems = (secondSetDictList || []).filter(
    //   (item) => item.name && item.name.toLowerCase().includes(filterPattern)
    // );

    // if (filteredItems.length > 0) {
    //   setSecondSetDictList(filteredItems);
    //   showFilterListView();
    // } else {
    //   setItemList([]);
    //   setSecondSetDictList([]);
    //   showDefaultView();
    // }
    setItemList([""]);
    // setSecondSetDictList([]);
    const _text = searchText.toLowerCase();
    console.log("dist", _text);
    console.log(secondSetDictList, "secondSetDictList");
    const filterPattern = _text.trim();

    const filteredItems =
      secondSetDictList &&
      secondSetDictList != null &&
      secondSetDictList.length > 0
        ? secondSetDictList.filter(
            (item) =>
              item.name != null &&
              item.name.length > 0 &&
              item.name.toLowerCase().includes(filterPattern)
          )
        : [];
    console.log("filteredItems", filteredItems);

    if (filteredItems.length > 0) {
      setItemList(filteredItems);
      // setSecondSetDictList(filteredItems);
      showFilterListView();
    } else {
      // setSecondSetDictList([]);
      setItemList([]);

      showDefaultView();
      setApiRequest(false);
    }
  };

  const checkProbabilityFirstSet = async () => {
    const _text = searchText.replace(" ", "").toLowerCase();
    console.log(_text, "_text");
    let itemList = [];
    let probHighest = 0.0;
    let probability = 0.0;
    setItemList([]);
    setSecondSetDictList([]);
    if (Array.isArray(initialDictList) && initialDictList.length > 0) {
      initialDictList?.forEach((item) => {
        if (item.name != null && item.name.length > 0) {
          if (item.name && item.name.toLowerCase().includes(_text)) {
            probability = parseFloat(_text.length / item.name.length);
            if (searchText.length > 3 && probability > 0.5) {
              if (probability > probHighest) {
                probHighest = probability;
                setType(item.type);
                setValue(item.name);
              }
              itemList.push(item);
            } else if (searchText.length == 3 && probability >= 0.3) {
              if (probability > probHighest) {
                probHighest = probability;
                setType(item.type);
                setValue(item.name);
              }

              itemList.push(item);
            }
          }
        }
      });
    }
    if (itemList.length > 0) {
      console.log(itemList, "itemList");

      setSecondSetDictList(itemList);
      // itemList = [];
      getsearchDict();
    } else {
      filterFirstSet();
    }
  };

  const getsearchDict = async () => {
    showDefaultView();
    if (!searchText) {
      return;
    }
    try {
      const response = await api.get("/search/get_search_dict", {
        params: {
          api_token: localStorage.getItem("apiToken"),
          type,
          value,
        },
      });

      if (
        response.status === 200 &&
        Array.isArray(response.data.data) &&
        response.data.data.length > 0
      ) {
        const data = response.data.data;
        setDictList(data);

        if (type === "") {
          setInitialDictList(data);
        } else {
          setSecondSetDictList(data);
        }
      }
    } catch (error) {
    } finally {
      console.log(textLength, "searchText.length");

      if (textLength > 2) {
        setShowData(true);
        showFilterListView();
      }
    }
  };

  const searchSuggestions = () => {
    if (searchText === "" || searchText === null) {
      setType("");
      setValue("");
      showDefaultView();
      setApiRequest(false);
      console.log("first");
      // return;
    } else {
      console.log("sec");

      let dict = dictList.length > 0 ? dictList[0] : null;
      if (dict) {
        console.log("dist");
        if (dict != null) {
          if (dict.type != null && dict.type === "" && searchText.length > 2) {
            checkProbabilityFirstSet();
          } else {
            if (secondSetDictList.length > 0) {
              let stringArray = convertStringWithSpaceIntoArray(searchText);

              if (stringArray.length === 1) {
                checkProbabilityFirstSet();
              } else {
                filterSecondSet();
              }
            } else {
              checkProbabilityFirstSet();
            }
          }
        } else {
          if (secondSetDictList.length > 0) {
            let stringArray = convertStringWithSpaceIntoArray(searchText);

            if (stringArray.length === 1) {
              checkProbabilityFirstSet();
            } else {
              filterSecondSet();
            }
          } else {
            checkProbabilityFirstSet();
          }
        }
      }
    }
  };

  const clearFilters = () => {
    setSearchText("");
    setSearchGender("");
    setSearchGenderId("");
    setSearchCategoryId("");
    setSearchCategoryName("");
    setSearchSegmentId("");
    setSearchSegmentName("");
    setSearchSubCategoryId("");
    setSearchSubCategoryName("");
    setColorIdList("");
    setCatList([]);
    setCategoryList([]);
    setColorNameList([]);
    setVisibleView("default");
  };

  const showDefaultView = () => {
    setShowSuggestionView(false);
  };
  const showFilterListView = () => {
    setShowSuggestionView(true);
  };

  useEffect(() => {
    let delayTimer;
    if (!isLoading) {
      setShowData(true);
    } else {
      setShowData(false);
    }

    return () => clearTimeout(delayTimer);
  }, [isLoading]);

  const searchDone = () => {
    if (searchText.length > 0) {
      const filterPattern = searchText.toLowerCase().trim();

      const list = dictList.filter(
        (item) =>
          item.name != null &&
          item.name.length > 0 &&
          item.name.toLowerCase() === filterPattern

        // (item) => item.name && item.name.toLowerCase() === filterPattern
      );
      if (list.length > 0) {
        setFilteredList(list);
        let firstItem = list[0];
        let colorIdToNavigate = "";
        if (firstItem.type != null && firstItem.type !== "") {
          if (
            ["category", "segment", "sub_category"].includes(firstItem.type)
          ) {
            colorIdToNavigate = "";
          } else {
            colorIdToNavigate = firstItem.color_id;
          }
        } else {
          if (type === "") {
            colorIdToNavigate = firstItem.color_id;
          } else {
            if (
              ["category", "segment", "sub_category"].includes(firstItem.type)
            ) {
              colorIdToNavigate = "";
            } else {
              colorIdToNavigate = firstItem.color_id;
            }
          }
        }

        setColorIds(colorIdToNavigate);

        setSearchGenderId(firstItem.gender_id);
        setSearchSegmentId(firstItem.segment_id);
        setSearchCategoryId(firstItem.category_id || "");
        setSearchCategoryName(firstItem.category_name || "");
        setSearchSubCategoryId(firstItem.sub_category_id || "");
        setSearchSubCategoryName(firstItem.sub_category_name || "");
        if (searchCategoryName) {
          setCatList((prev) => [...prev, searchCategoryName]);
          setCategoryList((prev) => [...prev]);
          setCategoryNameList((prev) => [...prev, searchCategoryName]);
        }
        if (searchSubCategoryName) {
          setCatList((prev) => [...prev, searchSubCategoryName]);
          setCategoryList((prev) => [...prev]);
          setCategoryNameList((prev) => [...prev, searchSubCategoryName]);
        }
        setIsPopupOpen(false);

        navigate(
          `/shop/${searchCategoryId || ""}${
            searchGenderId ? `/${searchGenderId}` : ""
          }${searchSegmentId ? `/${searchSegmentId}` : ""}${
            searchSubCategoryId ? `/${searchSubCategoryId}` : ""
          }`,
          {
            state: {
              categoryName: searchCategoryName,
              subCategoryName: searchSubCategoryName,
              subCategoryId: searchSubCategoryId,
              categoryId: searchCategoryId,
              color_id: colorIdToNavigate,
            },
          }
        );
      } else {
        getSearchResults(searchText, value, type);
      }
    } else {
      showDefaultView();
    }
  };
  const getSearchResults = async (searchText, value, type) => {
    console.log(searchText, value, type, "searchText, value, type");
    try {
      const response = await api.get("/search/get_search_results", {
        params: {
          api_token: localStorage.getItem("apiToken"),
          type: type || "",
          search_pattern: searchText,
          dict_pattern: value || "",
          page: 0,
        },
      });

      if (response.data && response.data.status === 200) {
        const getSearchResultResponse = response.data;
        if (
          getSearchResultResponse.segments &&
          getSearchResultResponse.segments.length > 0
        ) {
          const firstSegment = getSearchResultResponse.segments[0];
          const search_gender_id = firstSegment.gender_id;
          const search_segment_id = firstSegment.segment_id;

          if (
            getSearchResultResponse.found_type &&
            getSearchResultResponse.found_type.length > 0
          ) {
            const foundTypeList = getSearchResultResponse.found_type;

            const categoryIds = new Set();
            const categoryNameList = [];
            const subCategoryNameList = [];
            const subCategoryIds = new Set();
            const colorIds = new Set();
            const colorNameList = [];

            foundTypeList.forEach((item) => {
              switch (item.type_name) {
                case "category":
                  categoryIds.add(item.type_id);
                  if (!categoryNameList.includes(item.name)) {
                    categoryNameList.push(item.name);
                  }
                  break;
                case "sub_category":
                  subCategoryIds.add(item.type_id);
                  if (!subCategoryNameList.includes(item.name)) {
                    subCategoryNameList.push(item.name);
                  }
                  break;
                case "color":
                  colorIds.add(item.type_id);
                  if (!colorNameList.includes(item.name)) {
                    colorNameList.push(item.name);
                  }
                  break;
                default:
                  break;
              }
            });

            const categoryIdArray = Array.from(categoryIds);
            const subCategoryIdArray = Array.from(subCategoryIds);
            const colorIdArray = Array.from(colorIds);

            setIsPopupOpen(false);

            const navigatePath = `/shop/${categoryIdArray[0] || ""}${
              search_gender_id ? `/${search_gender_id}` : ""
            }${search_segment_id ? `/${search_segment_id}` : ""}${
              subCategoryIdArray[0] ? `/${subCategoryIdArray[0]}` : ""
            }`;

            navigate(navigatePath, {
              replace: true,
              state: {
                categoryName: categoryNameList,
                subCategoryName: subCategoryNameList,
                subCategoryId: subCategoryIdArray,
                categoryId: categoryIdArray,
                colorId: colorIdArray,
                responseDetail: {
                  status: response.data.status,
                  data: response.data.data,
                  total_count: response.data.total_count,
                },
              },
            });
          }
        }
      } else {
        throw new Error("Failed to fetch data: No valid response.");
      }
    } catch (error) {}
  };
  // const handleItemClick = (suggestion, index) => {
  //   // console.log("suggestion, index", suggestion, index);
  //   setActiveIndex(index);
  //   onItemClick(suggestion);
  // };
  const handleItemClick = (suggestion, index) => {
    // console.log("suggestion, index", suggestion, index);
    setActiveIndex(index);
    // onItemClick(suggestion);
    console.log("segment", suggestion.type);

    if (suggestion.type != null) {
      // Check the type and call onItemClick accordingly
      switch (suggestion.type) {
        case "segment":
          console.log("segment");
          onItemClick(
            "",
            "",
            "",
            "",
            suggestion.gender_id,
            "",
            suggestion.id,
            suggestion.name,
            "",
            "",
            suggestion.type
          );
          break;
        case "category":
          onItemClick(
            suggestion.id,
            suggestion.name,
            "",
            "",
            suggestion.gender_id,
            "",
            suggestion.segment_id,
            "",
            "",
            "",
            suggestion.type
          );
          break;
        case "sub_category":
          onItemClick(
            suggestion.category_id,
            suggestion.category_name,
            "",
            "",
            suggestion.gender_id,
            "",
            suggestion.segment_id,

            "",
            suggestion.id,
            suggestion.name,
            suggestion.type
          );
          break;
        case "color":
          onItemClick(
            "",
            "",
            suggestion.id,
            suggestion.name,
            "",
            "",
            "",
            "",
            "",
            "",
            suggestion.type
          );
          break;
        default:
          // Fallback for unknown types, you could log or handle it differently if needed
          break;
      }
    } else {
      // Handle the case where data.type is null or undefined
      let sub_category_id = suggestion.sub_category_id ?? "";
      let sub_category_name = suggestion.sub_category_name ?? "";

      onItemClick(
        suggestion.category_id,
        suggestion.category_name,
        suggestion.color_id,
        suggestion.color_name,
        suggestion.gender_id,
        suggestion.name,
        suggestion.segment_id,
        suggestion.segment_name,
        sub_category_id,
        sub_category_name,
        ""
      );
    }
  };

  const onItemClick = (
    category_id,
    category_name,
    color_id,
    color_name,
    gender_id,
    name,
    segment_id,
    segment_name,
    sub_category_id,
    sub_category_name,
    _type
  ) => {
    // Handle the logic here for onItemClick
    console.log(
      category_id,
      category_name,
      color_id,
      color_name,
      gender_id,
      name,
      segment_id,
      segment_name,
      sub_category_id,
      sub_category_name,
      _type
    );
    clearFilters();
    setSearchText(name);
    const genderMapping = { 1: "Men", 2: "Women", 3: "Unisex" };
    setSearchGender(genderMapping[gender_id] || "");
    setSearchGenderId(gender_id);
    setSearchCategoryId(category_id);
    setSearchCategoryName(category_name);
    setSearchSegmentId(segment_id);
    setSearchSegmentName(segment_name);
    setSearchSubCategoryId(sub_category_id);
    setSearchSubCategoryName(sub_category_name);

    const updatedCatList = [...catList];
    const updatedCategoryList = [...categoryList];
    const updatedColorNameList = [...colorNameList];

    if (sub_category_name) {
      updatedCatList.push(sub_category_name);
      updatedCategoryList.push({
        name: sub_category_name,
        type: "subCategories",
      });
    } else {
      updatedCatList.push(category_name);
      updatedCategoryList.push({
        name: category_name,
        type: "categories",
      });
    }

    if (_type != null && _type.length > 0) {
      if (
        _type === "category" ||
        _type === "segment" ||
        _type === "sub_category"
      ) {
        setColorIdList("");
      } else {
        if (type.length === 0) {
          if (color_name.length > 0) {
            updatedCatList.push(color_name);
            updatedCategoryList.push({ name: color_name, type: "color" });
          }
          updatedColorNameList.push(color_name);
          setColorIdList(color_id);
        } else {
          if (
            type === "category" ||
            type === "segment" ||
            type === "sub_category"
          ) {
            setColorIdList("");
          } else {
            if (color_name.length > 0) {
              updatedCatList.push(color_name);
              updatedCategoryList.push({ name: color_name, type: "color" });
            }
            updatedColorNameList.push(color_name);
            setColorIdList(color_id);
          }
        }
      }
      // if (["category", "segment", "sub_category"].includes(_type)) {
      //   setColorIdList("");
      // }
      // else if (color_name && !colorNameList.includes(color_name)) {
      //   updatedCatList.push(color_name);
      //   updatedCategoryList.push({ name: color_name, type: "color" });
      //   updatedColorNameList.push(color_name);
      //   setColorIdList(color_id);
      // }
    } else {
      if (type.length === 0) {
        if (color_name.length > 0) {
          updatedCatList.push(color_name);
          updatedCategoryList.push({ name: color_name, type: "color" });
        }
        updatedColorNameList.push(color_name);
        setColorIdList(color_id);
      } else {
        if (
          type === "category" ||
          type === "segment" ||
          type === "sub_category"
        ) {
          setColorIdList("");
        } else {
          if (color_name.length > 0) {
            updatedCatList.push(color_name);
            updatedCategoryList.push({ name: color_name, type: "color" });
          }
          updatedColorNameList.push(color_name);
          setColorIdList(color_id);
        }
      }
    }

    setCatList(updatedCatList);
    setCategoryList(updatedCategoryList);
    setColorNameList(updatedColorNameList);

    setVisibleView("filter");
    setCallFrom("search");
    setIsPopupOpen(false);
    navigate(
      `/shop/${category_id || ""}${gender_id ? `/${gender_id}` : ""}${
        segment_id ? `/${segment_id}` : ""
      }${sub_category_id ? `/${sub_category_id}` : ""}`,
      {
        replace: true,
        state: {
          categoryName: category_name,
          subCategoryName: sub_category_name,
          subCategoryId: sub_category_id,
          categoryId: category_id,
          color_id: color_id,
        },
      }
    );
  };

  return (
    <>
      <div className="search">
        <input
          type="text"
          className={`searchTerm ${isFocused ? "focused" : ""}`}
          placeholder="What can I help you find?"
          value={searchText}
          onFocus={() => {
            setIsFocused(true);
            setIsPopupOpen(true);
          }}
          onBlur={() => setIsFocused(false)}
          onChange={onTextChanged}
        />
        <button type="submit" className="searchButton">
          <img
            src="/images/search-icon.svg"
            height={14}
            width={14}
            alt="Search"
          />
        </button>
        {isPopupOpen && (
          <>
            <div
              className="search-popup-overlay"
              onClick={() => setIsPopupOpen(false)}
            ></div>
            <div className="search-popup">
              <div className="popup-heading ltr-gnxrcx e1q69ps20 logo">
                <img src="/images/topnavi.png" alt="Logo" />
              </div>
              <div className="ltr-1chvxl9">
                <button
                  className="close-button"
                  onClick={() => setIsPopupOpen(false)}
                >
                  &times;
                </button>
                <div className="ltr-5oi22u e1v87k540">
                  <form className="ltr-1rtrm5j">
                    <div
                      className={`popup-input-container ltr-pn3m8w ${
                        isFocused ? "focused" : ""
                      }`}
                    >
                      <img
                        src="/images/search-icon.svg"
                        height={18}
                        width={22}
                        alt="Search"
                        className="input-icon"
                      />
                      <input
                        type="search"
                        className="popup-search-input ltr-7ggsuw"
                        placeholder="What can I help you find?"
                        value={searchText}
                        onChange={onTextChanged}
                        onFocus={() => {
                          setIsFocused(true);
                          setIsPopupOpen(true);
                        }}
                        onBlur={() => setIsFocused(false)}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            searchDone();
                          }
                        }}
                      />
                      <button
                        aria-label="Clear search input"
                        type="button"
                        onClick={() => setSearchText("")}
                        className="ltr-lquw6d"
                      >
                        &times;
                      </button>
                    </div>
                  </form>

                  <ul className="item-list ltr-1w59fu7">
                    {itemList.length > 0
                      ? // If itemList is not empty, render this
                        itemList.map((item, index) => (
                          <li
                            key={index}
                            className="item secondSetDictList ltr-e2nkbz ewdd5xb1"
                            onClick={() => handleItemClick(item, index)}
                          >
                            {item.name}
                          </li>
                        ))
                      : // If itemList is empty and conditions are met, render suggestions
                        showData &&
                        secondSetDictList?.length > 0 &&
                        searchText?.length > 2 &&
                        showSuggestionView &&
                        secondSetDictList.map((suggestion, index) => (
                          <li
                            key={index}
                            className="item secondSetDictList ltr-e2nkbz ewdd5xb1"
                            onClick={() => handleItemClick(suggestion, index)}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchComponent;
