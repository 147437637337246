import React, { useEffect } from "react";
import { useCurrency } from "../../context/CurrencyContext";
const getCountryCodeByDomain = () => {
  const hostname = window.location.hostname;
  const port = window.location.port;

  if (hostname === "fashionxglobal.com" || port === "3001") {
    return "US";
  } else if (hostname === "modestrend.com" || port === "3002") {
    return "TR";
  } else if (hostname === "dev.modestrend.com" || port === "3002") {
    return "TR";
  } else if (hostname === "asianethnics.com" || port === "3003") {
    return "IN";
  } else {
    return "TR";
  }
};
const CurrencySelector = () => {
  const {
    currencyList,
    selectedCurrency,
    setCurrencyPreference,
    isLoading,
    setSelectedCurrency,
    selectedCurrencyCode,
  } = useCurrency();
  const countryCode = getCountryCodeByDomain() || "US";
  const handleChange = async (event) => {
    const newCurrencyId = event.target.value;
    const newCurrencyCode =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-curcode"
      );
    const newCurrencySymbol =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-cursymbol"
      );

    localStorage.setItem("currencyCode", newCurrencyCode);
    localStorage.setItem("currencySymbol", newCurrencySymbol);
    localStorage.setItem("selectedCurrency", newCurrencyId);
    await setCurrencyPreference(newCurrencyId);
    const button = document.getElementById("clearButton");
    if (button) {
      button.click();
    }
  };
  useEffect(() => {
    if (!selectedCurrency && selectedCurrencyCode) {
      const usdCurrency = currencyList.find(
        (currency) => currency.code === selectedCurrencyCode
      );

      if (usdCurrency) {
        setSelectedCurrency(usdCurrency.currency_id);
        localStorage.setItem("currencyCode", usdCurrency.code);
        localStorage.setItem("currencySymbol", usdCurrency.symbol);
      }
    }
    // else {
    //   const usdCurrency = currencyList.find(
    //     (currency) => currency.code === selectedCurrencyCode
    //   );
    //   if (usdCurrency) {
    //     setSelectedCurrency(usdCurrency.currency_id);
    //     localStorage.setItem("currencyCode", usdCurrency.code);
    //     localStorage.setItem("currencySymbol", usdCurrency.symbol);
    //   }
    // }
  }, [currencyList, selectedCurrency, setSelectedCurrency]);
  useEffect(() => {
    if (selectedCurrency) {
      localStorage.setItem("currencyId", selectedCurrency);
    }
  }, [selectedCurrency]);

  return (
    <select value={selectedCurrency || ""} onChange={handleChange}>
      {currencyList?.map((currency) => (
        <option
          key={currency.currency_id}
          data-cursymbol={currency.symbol}
          data-curcode={currency.code}
          value={currency.currency_id}
        >
          {currency.code}
        </option>
      ))}
    </select>
  );
};

export default CurrencySelector;
