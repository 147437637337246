import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isMobile } from "react-device-detect";

const SubCategoryList = ({ categoryData, onSubCategoryChange, isLoading }) => {
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const [selectedSubCategory, setSelectedSubCategory] =
    useState(sub_category_id);
  const [isListVisible, setIsListVisible] = useState(false);
  const handleRadioChange = (subCategoryId, subCategoryName, category_name) => {
    const button = document.getElementById("clearButton");
    if (button) {
      button.click();
    }
    setSelectedSubCategory(subCategoryId);
    onSubCategoryChange(subCategoryId, subCategoryName, category_name);

    // Hide the sub-category list after selecting an item
    setIsListVisible(false);
  };

  useEffect(() => {
    setSelectedSubCategory(sub_category_id || "");
  }, [sub_category_id]);

  const toggleListVisibility = () => {
    setIsListVisible((prev) => !prev);
  };
  // If no category data exists, return null or a placeholder message
  if (!categoryData || categoryData.length === 0) {
    return null;
  }
  // If data is loading, show skeletons
  if (isLoading) {
    return (
      <div className="sub-category-list">
        {!isMobile && (
          <>
            <h4 className="sub-category-heading">Sub Category</h4>
            <ul>
              {[...Array(5)].map((_, index) => (
                <li key={index}>
                  <Skeleton
                    height={40}
                    width={300}
                    baseColor="#ffffff"
                    highlightColor="#f8f8f8"
                    enableAnimation={false}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="sub-category-list">
      <button className="toggle-button" onClick={toggleListVisibility}>
        {isListVisible ? (
          <>
            Sub Category
            <span className="icon icon--arrow-up icon--lg">
              <svg
                viewBox="0 0 16 16"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                width="18"
                height="18"
              >
                <path
                  stroke="white"
                  strokeWidth="0.6"
                  d="M8 5.5a.751.751 0 0 1 .55.24l3.25 3.5a.75.75 0 1 1-1.1 1.02L8 7.352 5.3 10.26a.75.75 0 1 1-1.1-1.021l3.25-3.5a.751.751 0 0 1 .55-.24z"
                ></path>
              </svg>
            </span>
          </>
        ) : (
          <>
            Sub Category
            <span className="icon icon--arrow-down icon--lg">
              <svg
                viewBox="0 0 16 16"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                width="18"
                height="18"
              >
                <path
                  stroke="white"
                  strokeWidth="0.6"
                  d="M8 10.5a.751.751 0 0 1-.55-.24L4.2 6.76A.75.75 0 1 1 5.3 5.74L8 8.648l2.7-2.908a.75.75 0 1 1 1.1 1.021l-3.25 3.5a.751.751 0 0 1-.55.24z"
                ></path>
              </svg>
            </span>
          </>
        )}
      </button>

      <div className={`category-list ${isListVisible ? "visible" : "hidden"}`}>
        {Array.isArray(categoryData) && categoryData.length > 0 && (
          <>
            <h4 className="sub-category-heading">Sub Category</h4>
            <ul>
              {categoryData.map((item) => (
                <li
                  key={item.sub_category_id}
                  className={`${
                    selectedSubCategory === item.sub_category_id ||
                    sub_category_id === item.sub_category_id
                      ? "active"
                      : ""
                  }`}
                >
                  <label>
                    <input
                      type="radio"
                      name="subCategory"
                      value={item.sub_category_id}
                      checked={selectedSubCategory === item.sub_category_id}
                      onChange={() =>
                        handleRadioChange(
                          item.sub_category_id,
                          item.sub_category_name,
                          item.category_name
                        )
                      }
                    />
                    {item.sub_category_name}
                  </label>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <style jsx="true">{`
        .toggle-button {
          display: none;
          padding: 16px;
          background-color: #f6f6f6;
          color: #001017;
          border: none;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
        }

        .category-list.visible {
          max-height: 500px;
          opacity: 1;
          overflow: auto;
        }

        .sub-category-heading {
          margin-bottom: 10px;
        }

        @media (max-width: 768px) {
          .toggle-button {
            text-align: left;
            justify-content: space-between;
            display: flex;
            background: #f4f4f4;
            width: 100%;
            max-height: 53px;
          }

          .sub-category-heading {
            display: none !important;
          }

          button.toggle-button {
            background: #f6f6f6;
            justify-content: space-between;
            font-family: "Poppins", sans-serif !important;
            font-size: 14px;
            color: #000;
            display: flex;
          }
        }

        @media (min-width: 769px) {
          .category-list {
            display: block !important;
            max-height: none;
            opacity: 1;
          }

          .toggle-button {
            display: none;
          }

          .sub-category-heading {
            display: block;
          }
        }
      `}</style>
    </div>
  );
};

export default SubCategoryList;
